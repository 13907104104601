import React, { useState } from 'react';
import { useRequest } from 'ahooks';
import Styles from './index.module.css';
import ContactPng from '../../static/contact.png';
import { Flex, Input, Space, Col, Row, Form, Button, FormProps, message } from 'antd';
import SubWrap from '../../components/SubWrap';
import axios from 'axios';
import { isMobile } from '../../utils';
import Zmage from 'react-zmage';

interface IResult {
  // 证书名称
  cardName: string;
  // 证书编号
  cardNum: string;
  // 身份证号
  idCard: string;
  // 姓名
  name: string;
  // 毕业日期
  endDate: string;
  // 性别
  sex: string;
  // 专业
  major: string;
  // 图片base64
  url: string;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};


type FieldType = {
  cardNum?: string;
  idCard?: string;
  name?: string;
};

function Search() {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [result, setResult] = useState<IResult | null>(null);
  const [form] = Form.useForm();

  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
    try {
      setError('');
      setLoading(true);
      setResult(null);
      const result = await axios.post('/query', values);
      setLoading(false);
      if(result?.data?.data?.cardNum) {
        setResult(result.data?.data);
      } else {
        setError('查询不到证书，请检查查询条件。');
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setError('查询异常，请稍后再试。');
    }
  };


  return (
    <div>
      <SubWrap
        title={'证书查询'}
        hideContentTitle
        style={{
          background: '#f0f6ff'
        }}
      >
        <Flex vertical justify='space-between' align='center' className={Styles['content']}>
          <div className={Styles['title']}>证书查询</div>
          <Form
            {...formItemLayout}
            form={form}
            onFinish={onFinish}
            style={{ width: isMobile ? '' : 500 }}
          >

            <Form.Item label="证书编号" name="cardNum" rules={[{ required: true, message: '请输入证书编号' }]}>
              <Input placeholder='请输入证书编号' />
            </Form.Item>

            <Form.Item label="身份证号" name="idCard" rules={[{ required: true, message: '请输入身份证号' }]}>
              <Input placeholder='请输入身份证号' />
            </Form.Item>
            <Form.Item label="学员姓名" name="name" rules={[{ required: true, message: '请输入学员姓名' }]}>
              <Input placeholder='请输入学员姓名' />
            </Form.Item>

            <Flex align='center' justify='center'>
              <Button type="primary" htmlType="submit" loading={loading}>
                查询
              </Button>
            </Flex>
          </Form>

        </Flex>
        {
          error &&
          <Flex vertical justify='space-between' align='center' className={Styles['result']}>
            <div className={Styles['title']}>查询结果</div>
            <div style={{ color: '#999' }}>{error}</div>
          </Flex>
        }
        {
          result &&

          <Flex vertical justify='space-between' align='center' className={Styles['result']}>
            <div className={Styles['title']}>查询结果</div>
            <div style={{ padding: '0 20px' }}>
              <Row className={Styles['row']} style={{ borderTop: '1px solid #FF5B00' }}>
                <Col span={8}>证书名称：</Col>
                <Col span={16}>{'职业技能培训证书'}</Col>
              </Row>
              <Row className={Styles['row']}>
                <Col span={8}>证书编号：</Col>
                <Col span={16}>{result?.cardNum}</Col>
              </Row>

              <Row className={Styles['row']}>
                <Col span={8}>学员姓名：</Col>
                <Col span={16}>{result?.name}</Col>
              </Row>
              <Row className={Styles['row']}>
                <Col span={8}>学员性别：</Col>
                <Col span={16}>{result?.sex}</Col>
              </Row>

              <Row className={Styles['row']}>
                <Col span={8}>学员身份证号：</Col>
                <Col span={16}>{result?.idCard}</Col>
              </Row>

              <Row className={Styles['row']}>
                <Col span={8}>颁发日期：</Col>
                <Col span={16}>{result?.endDate}</Col>
              </Row>


              <div style={{ marginTop: 40 }}>
                {
                  result?.url &&
                  <img
                    src={`data:image/png;base64,${result.url}`}
                    width={400}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      Zmage.browsing({
                        hideOnScroll: false,
                        src: `data:image/png;base64,${result.url}`
                      })
                    }}
                  />
                }
              </div>

            </div>
          </Flex>
        }
      </SubWrap>
    </div>
  );
}

export default Search;
