import React from 'react';
import { useRequest } from 'ahooks';
import Styles from './index.module.css';
import Info01 from '../../../static/info_01.png';
import Info02 from '../../../static/info_02.png';
import Info03 from '../../../static/info_03.png';
import Info04 from '../../../static/info_04.png';
import Info05 from '../../../static/info_05.png';
import Info06 from '../../../static/info_06.png';
import { Flex, Input, Space, Breadcrumb, Menu } from 'antd';
import SubWrap from '../../../components/SubWrap';
import { isMobile } from '../../../utils';



function Info() {
  useRequest(async () => {

  });




  return (
    <div>
      <SubWrap
        title={'学校概况'}
        menus={
          [
            {
              title: '学校简介',
              path: '#/overview/info'
            },
            {
              title: '荣誉资质',
              path: '#/overview/qualifications'
            }
          ]
        }
      >
        <Flex gap={'large'} className={Styles['content']} vertical>
          <Flex vertical>
            <p>
              阜大嫂职业技能培训学校，作为颍泉区人力资源和社会保障局精心指定的定点培训机构，目标是致力于为社会培养高素质、专业化的技能型人才。学校不仅承载着推动地区职业技能教育发展的重任，更是广大求职者提升自我、实现职业梦想的摇篮。
            </p>
            <p>
              为了确保教学质量与培训效果，阜大嫂职业技能培训学校构建了一套科学完善的运营体系。这一体系涵盖了从课程设计、教学管理到学员评估的每一个环节，旨在通过系统化的流程管理，为学员提供高效、有序的学习环境。同时，学校配备了先进的一体化教学设备，这些设备不仅功能全面，而且紧跟行业发展趋势，确保学员能够接触到最前沿的技术和知识。
            </p>
            <p>
              在实操训练方面，学校更是下足了功夫。学校拥有与理论教学紧密配套的实操训练设施，这些设施模拟了真实的工作场景，为学员提供了宝贵的实践机会。通过大量的实操练习，学员不仅能够巩固所学知识，还能在实践中发现问题、解决问题，从而全面提升自己的职业技能。
            </p>
            <p>
              师资力量是阜大嫂职业技能培训学校的核心竞争力之一。学校拥有一支由医院本科、大专学历持有中级以上职称的专业老师团队。这些老师不仅具备扎实的理论基础，更拥有丰富的实践经验。他们在教学过程中，能够结合行业实际，为学员提供深入浅出的讲解和有针对性的指导，帮助学员快速掌握核心技能。
            </p>
            <p>
              对于完成培训且考试合格的学员，阜大嫂职业技能培训学校将颁发结业证书。这份证书不仅是对学员学习成果的认可，更是他们进入职场、开启职业生涯的重要凭证。此外，学校还承诺为有意向就业的学员提供百分百的就业推荐服务。学校与多家医疗机构、养老机构等建立了紧密的合作关系，为学员提供了广阔的就业平台和丰富的就业机会。
            </p>
            <p>
              阜大嫂职业技能培训学校凭借其完善的运营体系、先进的教学设备、配套的实操训练设施以及强大的师资力量，成为了广大求职者提升职业技能、实现职业梦想的理想选择。
            </p>
          </Flex>

          <Flex wrap gap="large">
            <Flex style={{ width: isMobile ? '100%' : '' }} align='center' justify='center'>
              <img src={Info01} alt="" />
            </Flex>
            <Flex style={{ width: isMobile ? '100%' : '' }} align='center' justify='center'>
              <img src={Info02} alt="" />
            </Flex>
            <Flex style={{ width: isMobile ? '100%' : '' }} align='center' justify='center'>
              <img src={Info03} alt="" />
            </Flex>
            <Flex style={{ width: isMobile ? '100%' : '' }} align='center' justify='center'>
              <img src={Info04} alt="" />
            </Flex>
            <Flex style={{ width: isMobile ? '100%' : '' }} align='center' justify='center'>
              <img src={Info05} alt="" />
            </Flex>
            <Flex style={{ width: isMobile ? '100%' : '' }} align='center' justify='center'>
              <img src={Info06} alt="" />
            </Flex>
          </Flex>
        </Flex>
      </SubWrap>
    </div>
  );
}

export default Info;
