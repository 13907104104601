import React, { useEffect, useState } from 'react';
import Styles from './index.module.css';
import { useRequest } from 'ahooks';
import Logo from '../../logo.png';
import { Input, Popover, Space } from 'antd';
import {
  SearchOutlined
} from '@ant-design/icons';
import { isMobile } from '../../utils';
import {
  MenuOutlined
} from '@ant-design/icons';
import MenuMobile, { navItems } from '../Menu';


const Navs = [
  {
    title: '首页',
    pathname: '#/'
  },
  {
    title: '学校概况',
    pathname: '#/overview/info',
    children: [
      '#/overview/info',
      '#/overview/qualifications'
    ]
  },
  {
    title: '培训专业',
    pathname: '#/major/nurse',
    children: [
      '/major/nurse'
    ]
  },
  {
    title: '学校风采',
    pathname: '#/elegance/school',
    children: [
      '#/elegance/school',
      '#/elegance/student'
    ]
  },
  {
    title: '证书查询',
    pathname: '#/search'
  },
  {
    title: '联系我们',
    pathname: '#/contact',
  }
]

function NavBar() {
  const [open, setOpen] = useState(false);
  const [currentHash, setCurrentHash] = useState(window.location.hash);

  const hide = () => {
    setOpen(false);
  };

  useEffect(() => {
    window.onhashchange = () => {
      setCurrentHash(window.location.hash);
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }, []);
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return (
    <div className={Styles['nav-bar']}>
      <img src={Logo} alt="logo" className={Styles['nav-logo']} height={25} />

      {
        isMobile ?
          <Popover
            content={<MenuMobile onHide={hide} />}
            trigger='click'
            onOpenChange={handleOpenChange}
            open={open}
            placement={'bottomLeft'}

          >
            <MenuOutlined style={{paddingRight: 10}}/>
          </Popover>

          :
          <div className={Styles['nav']}>
            <div className='nav-items'>
              {
                navItems.map((nav, index) => {
                  if (isMobile) return null;
                  const isActive = nav.key === currentHash || nav.children?.find((childNav: any) => childNav.key === currentHash)
                  return (
                    <span
                      key={nav.key}
                      className={`${Styles['item']} ${isActive ? Styles['item-active'] : ''}`}
                      onClick={() => {
                        // setCurrentHash(nav.pathname);
                        window.location.hash = `${nav.key}`
                      }}
                    >
                      {nav.label}
                    </span>
                  )
                })
              }
            </div>
          </div>
      }


      {/* <SearchOutlined className={Styles['item']} /> */}

      {/* <Input.Search
          placeholder="搜索"
          style={{ width: 150 }}
          className={Styles['search']}
          onSearch={()=> {
            window.location.hash = '#/search'
          }}
          onClick={() => {
            window.location.hash = '#/search'
          }} /> */}

    </div>
  );
}

export default NavBar;
