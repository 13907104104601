import React, { useEffect, useState } from 'react';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';

export const navItems = [
  {
    key: '#/',
    label: '首页',
  },
  {
    key: '#/overview/info',
    label: '学校概况',
    children: [
      {
        key: '#/overview/info',
        label: '学校简介'
      },
      {
        key: '#/overview/qualifications',
        label: '荣誉资质'
      }
    ]
  },
  {
    key: '#/major/nurse',
    label: '培训专业',
  },
  {
    key: '#/elegance/school',
    label: '学校风采',
    children: [
      {
        key: '#/elegance/school',
        label: '校园面貌'
      },
      {
        key: '#/elegance/student',
        label: '学员风采'
      }
    ]
  },
  {
    key: '#/search',
    label: '证书查询',
  },
  {
    key: '#/contact',
    label: '联系我们',
  },
];


interface IProps {
  onHide: () => void;
}

const MenuMobile = (props: IProps) => {
  const { onHide } = props;

  const [currentHash, setCurrentHash] = useState(window.location.hash);
  console.log('currentHash', currentHash)
  useEffect(() => {
    window.onhashchange = () => {
      setCurrentHash(window.location.hash);
    }
  }, []);

  const onClick: MenuProps['onClick'] = (e) => {
    window.location.hash = `${e.key}`
    onHide?.();
  };

  return (
    (
      <Menu
        selectedKeys={[currentHash]}
        onClick={onClick}
        style={{ width: 256 }}
        mode="inline"
        items={navItems}

      />
    )
  )
};

export default MenuMobile;