

import React from 'react';
import Styles from './index.module.css';
import { useRequest } from 'ahooks';
import Logo from '../../logo.png';
import { Flex, Input, Space } from 'antd';
import { isMobile } from '../../utils';
import Guohui from '../../static/guohui.png';


function Bottom() {
  useRequest(async () => {

  });


  return (
    <Flex className={Styles['bottom']} vertical wrap>
      <Flex align='center' wrap={isMobile} vertical={isMobile} gap={10} justify='center'>
        <div>
          <h3 className={Styles['bottom-title']}>合作伙伴</h3>
          <Flex wrap gap={10}>
            <span>安徽阜大嫂护理机构服务有限公司</span>
            <span>上海瑞晟康护理服务有限公司阜阳分公司</span>
            <span>安徽世祥病人陪护服务有限公司</span>
          </Flex>
        </div>
      </Flex>

      <Flex style={{ marginTop: 20 }} vertical align='center' gap={5}>
        <div><span>Copyright© 2024-2025 All Rights Reserved 阜阳市阜大嫂职业技能培训学校有限公司@版权所有</span></div>
        <Flex align='center' gap={10} style={{marginTop: 10}} wrap>
          <Flex align='center'>
            <img src={Guohui} alt="" height={15} style={{ marginRight: 4 }} />
            <a href="https://beian.mps.gov.cn/#/query/webSearch?code=34120402001579" rel="noreferrer" target="_blank">皖公网安备34120402001579号</ a>
          </Flex>
          <a href="https://beian.miit.gov.cn" rel="noreferrer" target="_blank">皖ICP备2024069065号-1</ a>
        </Flex>
      </Flex>

    </Flex>
  );
}

export default Bottom;
