import React, { useState } from 'react';
import Styles from './index.module.css';
import { useRequest } from 'ahooks';
import School01 from '../../../static/school_01.png';
import School02 from '../../../static/school_02.png';
import School03 from '../../../static/school_03.png';
import School04 from '../../../static/school_04.png';
import School05 from '../../../static/school_05.png';
import School06 from '../../../static/school_06.png';
import School07 from '../../../static/school_07.png';
import School08 from '../../../static/school_08.png';

import Sence01 from '../../../static/sence_01.png';
import Sence02 from '../../../static/sence_02.png';
import Sence03 from '../../../static/sence_03.png';
import Sence04 from '../../../static/sence_04.png';
import Sence05 from '../../../static/sence_05.png';
import Sence06 from '../../../static/sence_06.png';
import Sence07 from '../../../static/sence_07.png';
import Sence08 from '../../../static/sence_08.png';
import Sence09 from '../../../static/sence_09.png';


import { Flex, Input, Space, Breadcrumb, Menu, Button } from 'antd';
import SubWrap from '../../../components/SubWrap';
import { isMobile } from '../../../utils';



function Student() {



  useRequest(async () => {

  });




  return (
    <div>
      <SubWrap
        title={'学校风采'}
        hideContentTitle
        menus={
          [
            {
              title: '校园面貌',
              path: '#/elegance/school'
            },
            {
              title: '学员风采',
              path: '#/elegance/student'
            }

          ]
        }
      >
        <Flex vertical className={Styles['school']}>

          <Flex vertical>
            <Flex className={Styles['title']} justify='space-around'>
              <h3 style={{marginBottom: 5}}>学校场地</h3>
            </Flex>
            <Flex className={Styles['desc']} justify='space-around'>700多平现代化教学场地</Flex>
            <Flex wrap={true} gap={'large'}>
              <Flex vertical gap={'small'} style={{ width: isMobile ? '100%' : '' }} align='center' justify='center'>
                <img src={School08} alt="" />
                <div className={Styles['card-title']}>综合服务台</div>
              </Flex>

              <Flex vertical gap={'small'} style={{ width: isMobile ? '100%' : '' }} align='center' justify='center'>
                <img src={School01} alt="" />
                <div className={Styles['card-title']}>护工之乡</div>
              </Flex>

              <Flex vertical gap={'small'} style={{ width: isMobile ? '100%' : '' }} align='center' justify='center'>
                <img src={School07} alt="" />
                <div className={Styles['card-title']}>学校走廊</div>
              </Flex>

              <Flex vertical gap={'small'} style={{ width: isMobile ? '100%' : '' }} align='center' justify='center'>
                <img src={School04} alt="" />
                <div className={Styles['card-title']}>学校会议室</div>
              </Flex>

              <Flex vertical gap={'small'} style={{ width: isMobile ? '100%' : '' }} align='center' justify='center'>
                <img src={School05} alt="" />
                <div className={Styles['card-title']}>学校教室</div>
              </Flex>

              <Flex vertical gap={'small'} style={{ width: isMobile ? '100%' : '' }} align='center' justify='center'>
                <img src={School03} alt="" />
                <div className={Styles['card-title']}>实操教室</div>
              </Flex>

              <Flex vertical gap={'small'} style={{ width: isMobile ? '100%' : '' }} align='center' justify='center'>
                <img src={School06} alt="" />
                <div className={Styles['card-title']}>学校食堂</div>
              </Flex>

              <Flex vertical gap={'small'} style={{ width: isMobile ? '100%' : '' }} align='center' justify='center'>
                <img src={School02} alt="" />
                <div className={Styles['card-title']}>教具陈列柜</div>
              </Flex>
            </Flex>
          </Flex>


          <Flex vertical style={{ marginTop: 30, borderTop: '1px solid #ebebeb ' }}>
            <Flex className={Styles['title']} justify='space-around'>
              <h3 style={{marginBottom: 5}}>教学现场</h3>
            </Flex>
            <Flex className={Styles['desc']} justify='space-around'>理论、实操相结合的现代化教学方式</Flex>
            <Flex wrap={true} gap={'large'}>
              <Flex vertical gap={'large'} style={{ width: isMobile ? '100%' : '' }} align='center' justify='center'>
                <img src={Sence01} alt="" />
              </Flex>

              <Flex vertical gap={'large'} style={{ width: isMobile ? '100%' : '' }} align='center' justify='center'>
                <img src={Sence02} alt="" />
              </Flex>

              <Flex vertical gap={'large'} style={{ width: isMobile ? '100%' : '' }} align='center' justify='center'>
                <img src={Sence03} alt="" />
              </Flex>

              <Flex vertical gap={'large'} style={{ width: isMobile ? '100%' : '' }} align='center' justify='center'>
                <img src={Sence04} alt="" />
              </Flex>

              <Flex vertical gap={'large'} style={{ width: isMobile ? '100%' : '' }} align='center' justify='center'>
                <img src={Sence05} alt="" />
              </Flex>

              <Flex vertical gap={'large'} style={{ width: isMobile ? '100%' : '' }} align='center' justify='center'>
                <img src={Sence06} alt="" />
              </Flex>

              <Flex vertical gap={'large'} style={{ width: isMobile ? '100%' : '' }} align='center' justify='center'>
                <img src={Sence07} alt="" />
              </Flex>

              <Flex vertical gap={'large'} style={{ width: isMobile ? '100%' : '' }} align='center' justify='center'>
                <img src={Sence08} alt="" />
              </Flex>
              <Flex vertical gap={'large'} style={{ width: isMobile ? '100%' : '' }} align='center' justify='center'>
                <img src={Sence09} alt="" />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </SubWrap>
    </div>
  );
}

export default Student;
