

import React, { CSSProperties } from 'react';
import Styles from './index.module.css';
import { useRequest } from 'ahooks';
import Logo from '../../logo.png';
import { Flex, Input, Space, Breadcrumb, Menu } from 'antd';
import SecondTop from '../../static/second_top.png';
import { isMobile } from '../../utils';


interface IProps {
  children: any;
  menus?: Array<{ title: string; path: string }>;
  topBar?: React.ReactElement;
  title: string;
  hideContentTitle?: boolean;
  style?: CSSProperties;
}

function SubWrap(props: IProps) {

  const { menus, title, children, hideContentTitle } = props;
  const currentTitle = menus?.find(menu => menu.path === window.location.hash)?.title;
  const hideMenu = !menus || menus?.length === 0;
  const items = [
    {
      title: '首页',
      href: '#/'
    },
    {
      title: currentTitle || title,
    }
  ].filter(item => !!item);


  return (
    <>
      <div className={Styles['second-top']}>

        {
          !isMobile && <img src={SecondTop} alt="" />
        }
        {
          !isMobile && <div>{props.title}</div>
        }
      </div>
      <div className={isMobile ? Styles['wrap-mobile'] : Styles['wrap']}>
        <Breadcrumb
          className={Styles['breadcrumb']}
          // @ts-ignore 
          items={items} />

        <Flex
          className={Styles['content']}
          justify='space-around'
          align='flex-start'
        >
          {
            !hideMenu && !isMobile &&
            <Flex className={Styles['left-menu']} vertical >
              <Flex className={Styles['menu-item-title']}>
                <div>{props.title}</div>
              </Flex>


              <Flex vertical>
                {

                  !hideMenu && menus?.map((menu, index) => {
                    const active = menu.path === window.location.hash;
                    return <Flex
                      key={index}
                      className={active ? Styles['menu-item-active'] : Styles['menu-item']}
                      onClick={() => {
                        window.location.hash = menu.path;
                      }}
                    >{menu.title}</Flex>
                  })
                }
              </Flex>
            </Flex>
          }
          <Flex
            className={Styles['right-content']}
            vertical
            align='center'
            style={props.style}
          >
            {
              !hideContentTitle &&
              <h3 className={Styles['content-title']}>{currentTitle}</h3>
            }
            {children}
          </Flex>
        </Flex>

      </div>
    </>
  );
}

export default SubWrap;
